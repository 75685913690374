/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJob = /* GraphQL */ `
  mutation CreateJob($input: jobNodeInput, $limit: Int, $nextToken: String) {
    createJob(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        contentType
        createdAt
        id
        jobIdentity
        jobName
        jobOwner
        jobStatus
        languageSource
        languageTargets
        sourceKey
        sourceStatus
        translateCallback
        translateKey
        translateStatus
      }
      nextToken
    }
  }
`;
